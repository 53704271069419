$navy: #074b70;
$orange: #f0a140;
$grey: #727272;
$lightBlue: #297eb2;
$offBlack: #181818;
$green: #02c430;
$lightGrey: #f1f4f9;

.err {
  border-color: red !important;
}
.err-box {
  background: #feedec;
  border: 1px solid #eb8181;
  border-radius: 4px;
  padding: 6px 15px 6px 10px;
  display: inline-block;
  width: 100%;
  li {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    position: relative;
    padding-left: 10px;
    color: #f06261;
    &::first-letter {
      text-transform: capitalize;
    }
  }
}
.stats {
  .user-stat {
    float: left;
    width: 50%;
    padding: 15px;
    border-left: 1px solid #e0e0e0;
  }
}
.page-profile-v2 {
  .user-stats {
    .user-stat {
      &:first-child {
        border-left-color: transparent;
      }
    }
  }
}
.counter {
  text-align: center;
}
.btn-red {
  background-color: red;
  color: white;
}
.btn-green {
  background-color: green;
  color: white;
}
#action-list {
  display: flex !important;
}
#caseId-input {
  padding-top: 0px;
}
.export-btn {
  margin-right: 20px;
  border-radius: 10px;
  margin-left: 30px;
}
.panel-heading {
  margin-bottom: 20px;
}
.filter-tab {
  margin-top: 20px;
}
.btn {
  margin-right: 15px;
}
.dataTables_wrapper {
  overflow-x: scroll !important;
}
.page-login-v2 {
  form {
    width: 400px !important;
  }
  &::before {
    background-image: none !important;
  }
}
.page-login-main {
  padding: 100px 60px 180px !important;
}
.btn-onc {
  background-color: #094b70;
  color: white;
  border-radius: 10px;
}
.btn-login {
  width: 80%;
}
.auth-form-field {
  width: 80% !important;
}
.auth-logo {
  max-width: 170px !important;
}
.auth-text {
  color: #094b70;
  font-size: 20px !important;
  font-weight: 400;
}
.auth-header {
  margin-bottom: 30px;
}
.auth-helper {
  color: #094b70;
  text-align: center;
  width: 80%;
  a {
    font-weight: bold;
    color: #094b70;
  }
}
.auth-register-description {
  width: 80%;
}
.dashboard-content {
  background-color: white;
}
.dashboard-header {
  padding: 30px;
  // display: flex;
}
.stats-box {
  border-radius: 15px;
  box-shadow: 3px 3px 5px 2px #ccc;
  margin: 30px;
  margin-top: 10px;
  .card-block {
    min-height: 250px;
  }
}
.stats-box-info {
  text-align: center;
  display: block;
  font-size: 4.5em;
  color: black;
  font-weight: bold;
}
.stats-box-footer {
  margin-top: 15px;
  text-align: center;
  p {
    font-weight: bold;
    color: #707070;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 0;
  }
  span {
    color: #07c100;
    font-weight: bold;
    font-size: 20px;
    margin-right: 5px;
  }
}
.stats-box-footer-diseases {
  margin-top: 30px;
  text-align: center;
  p {
    font-weight: bold;
    color: #707070;
    display: inline;
    margin-right: 10px;
  }
}
.empty-box {
  height: 300px;
}
.usable-indicator {
  height: 10px;
  width: 10px;
  background: linear-gradient(
    90deg,
    rgba(9, 75, 112, 1) 0%,
    rgba(126, 240, 144, 1) 100%
  );
  margin-right: 1px;
  border-radius: 50%;
  display: inline-block;
}

.average-indicator {
  height: 10px;
  width: 10px;
  background: linear-gradient(
    90deg,
    rgba(8, 71, 106, 1) 0%,
    rgba(224, 124, 238, 1) 36%
  );

  margin-right: 1px;
  border-radius: 50%;
  display: inline-block;
}

.paid-indicator {
  height: 10px;
  width: 10px;
  background: linear-gradient(
    90deg,
    rgba(150, 154, 255, 1) 0%,
    rgba(20, 0, 255, 1) 36%
  );
  margin-right: 1px;
  border-radius: 50%;
  display: inline-block;
}
.unusable-indicator {
  height: 10px;
  width: 10px;
  background: linear-gradient(
    90deg,
    rgba(251, 167, 20, 1) 0%,
    rgba(244, 34, 34, 1) 100%
  );
  margin-right: 1px;
  border-radius: 50%;
  display: inline-block;
}
.pending-indicator {
  height: 10px;
  width: 10px;
  background: linear-gradient(
    90deg,
    rgba(251, 251, 20, 1) 0%,
    rgba(251, 167, 20, 1) 100%
  );
  margin-right: 1px;
  border-radius: 50%;
  display: inline-block;
}
.btn-export {
  border-radius: 10px;
}
.stats-time-seprator {
  color: #3f51b6;
  font-size: 20px;
  margin-left: 30px;
}
.btn-blue {
  color: white;
  background-color: blue;
}
.payments-header {
  p {
    display: inline;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.btn-primary {
  height: auto;
  width: 80px;
  border-radius: 10px;
  font-size: 13px;
}
.btn-danger {
  height: auto;
  width: 80px;
  border-radius: 10px;
  font-size: 13px;
}
.panel-title-small {
  font-size: 15px;
  color: #707070;
}
.table-title {
  color: #3f51b6;
}
.submit-btn {
  border-radius: 10px;
  width: 100px;
}
.add-new-payment {
  margin-bottom: 20px;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
}

.cases-table {
  .table-action {
    margin-bottom: 30px;
  }
  .table-footer {
    margin-top: 30px;
    .pagination {
      display: inline-flex;
      float: right;
    }
  }
  .header {
    font-weight: 500;
  }
  .table-overflow {
    max-height: 700px;
    overflow-x: auto;
    overflow-y: auto;
  }
  table {
    position: relative;

    font-family: "Montserrat", sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-size: 15px;
    color: #727272;

    thead {
    }
    .table-header {
      font-weight: bold;
      th {
        background-color: white;
        z-index: 99999999;
        border-bottom: black;
        font-size: 10px;
      }
    }
    thead th {
      background: white;
      position: sticky;
      top: 0; /* Don't forget this, required for the stickiness */
      // box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
    }
    thead th:first-child {
      background: white;
      box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
      // position: sticky;
      left: 0;
      z-index: 4;
    }
    tbody tr {
      position: relative;
    }
    tbody tr td:first-child {
      background: white;
      top: auto;
      box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.4);
      position: sticky;
      left: 0;
      z-index: 2;
    }

    td {
      text-align: left;
      padding: 11px;
      min-width: 160px;
      border: 1px solid #e0e0e0;
      font-size: 12px;
    }

    tr:nth-of-type(even) {
      background-color: rgba(238, 238, 238, 0.3);
    }

    th {
      text-transform: uppercase;
      text-align: left;
      padding: 15px;
      border: 1px solid #e0e0e0;
    }
    .left-align {
      text-align: left;
    }
    .greyBG {
      background-color: #f1f4f9;
    }
    tr#t01 {
      border: 1px solid #e0e0e0;

      // width: 100%;
      // font-weight: 500;
      // background-color: #074b70;
      // color: white;
      // font-size: 14px;
      // padding: 8px;
      // position: sticky;
      // top: -1;
    }
    .button {
      font-weight: 300;
      background-color: #02c430;
      border: none;
      border-radius: 24px;
      color: white;
      padding: 5px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
    }
    .namelink {
      a {
        color: #297eb2;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .plus {
      color: #174b70;
      font-weight: 700;
      font-size: 18px;
      min-width: 10px;
    }
    .plusheader {
      font-weight: 700;
      font-size: 18px;
    }
  }
  .btn {
    border-radius: 15px;
    min-width: 90px;
    display: inline;
    margin: 5px;
    .btn-green {
      background-color: green;
      color: white;
    }
    .btn-red {
      background-color: red;
      color: white;
    }
    .btn-blue {
      background-color: blue;
      color: white;
    }
  }
}

.customSelect__control {
  width: 200px !important;
  border-color: rgba(186, 186, 186, 0.2) !important;
  border-radius: 13px !important;
  box-shadow: 2px 2px rgba(186, 186, 186, 0.3) !important;
}
.customSelect__input {
  .input {
    border-color: red !important;
    box-shadow: 0px 0px rgba(186, 186, 186, 0.3) !important;
  }
}
.sort-by-label {
  color: #297eb2;
  margin-right: 10px;
  margin-top: 7px;
}
.reportSelect__control {
  display: inline;
  width: 200px !important;
  border-color: rgba(186, 186, 186, 0.2) !important;
  border-radius: 13px !important;
  box-shadow: 2px 2px rgba(186, 186, 186, 0.3) !important;
}
.reportSelect__input {
  .input {
    border-color: red !important;
    box-shadow: 0px 0px rgba(186, 186, 186, 0.3) !important;
  }
}
.dashboard-site-title {
  margin-left: 30px;
  color: #707070;
  font-size: 30px;
}
.stats-comments {
  margin-left: 30px;
  margin-bottom: 30px;
  h2 {
    font-size: 20px;
  }
}
.dashboard-comments-list {
  width: 40%;
  padding: 0;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style-type: none;
}
.last-row-bold {
  tr:last-child {
    font-weight: bold;
  }
}
.payments-table {
  margin-top: 20px;
  tbody {
    & + thead {
      display: none;
    }
  }
  .dataTables_length {
    display: none;
  }
  .dataTables_info {
    display: none;
  }
}

.user-info {
  margin-left: 30px;

  .transeducers {
    margin-top: 20px;
    color: #094b70;
  }
  .form-group {
    margin-top: 10px;
    margin-bottom: 40px;
    .input-options {
      display: flex;
      justify-content: space-between;
    }

    input {
      // color: #575757;
      // font-weight: 400;
      font-size: 15px;
      border-color: rgba(186, 186, 186, 0.2);
      border-radius: 13px;
      box-shadow: 2px 2px rgba(186, 186, 186, 0.3);
    }

    .view-input {
      border: 0;
      outline: 0;
      border-radius: 0px;
      background: transparent;
      box-shadow: 0px 1px rgba(186, 186, 186, 0.3);
      border-bottom: 1px solid $lightGrey;
    }
  }

  .group-right {
    margin-left: auto;
  }

  .form-control {
    // color: #727272;
    // font-size: 15px;
    // font-weight: 100;
  }
}

.cases-table * {
  z-index: 9;
}

.customSelect__menu {
  z-index: 999 !important;
}

.legendIndicator {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}
